#sidebarDiv {
    width: 240px;
    padding: 10px;
    height: 200px;
}

#overlayDiv {
    z-index: 1;
    position: absolute;
    margin: auto auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 280px;
    height: 180px;
    padding: 10px;
    background-color: white;
    border: 1px solid grey;
    visibility: hidden;
}

input[type="text"] {
    width: 100%;
}