/* 提示框容器，类似于 React Bootstrap Tooltip 的目标元素 */
.file-container {
    position: relative;
    display: inline-block;
    cursor: pointer; /* 鼠标悬停时显示手型光标 */
  }
  
  /* 提示框文本，类似于 React Bootstrap Tooltip 的样式 */
  .owner-info {
    display: none;
    position: absolute;
    top: -28px; /* 负值将其移到对话框上方 */
    left: 0;
    background-color: #f1f0f0; /* 与对话框相同的背景颜色 */
    color: #333; /* 文本颜色 */
    border-radius: 5px; /* 与对话框相同的圆角 */
    padding: 5px;
    z-index: 1;
    min-width: 150px; /* 自定义宽度 */
    text-align: center; /* 居中文本 */
    font-size: 10px; /* 自定义字体大小 */
    white-space: nowrap; /* 防止文本折行 */
  }
  
  /* 鼠标悬停时显示提示框 */
  .file-container:hover .owner-info {
    display: block;
  }